import { MainCol, MainContainer, MainRow } from "components/common";
import {
  BuyHeader,
  BuySectionWrapper,
  Card,
  CardImgDiv,
  CardTextDiv,
  CardWrapper,
} from "./styles";
import { FaWallet } from "react-icons/fa";
import { divider, roadmap2 } from "assets";
import { AnimationOnScroll } from "react-animation-on-scroll";

const cardData = [
  {
    id: 1,
    header: "Create A Wallet",
    icon: <FaWallet className="icon" />,
    text: "To start, create a Phantom Wallet by downloading the Phantom Wallet app.",
  },
  {
    id: 2,
    header: "SEND SOL TO PHANTOM",
    icon: <FaWallet className="icon" />,
    text: "Purchase SOL (Solana's native cryptocurrency) and transfer it to your Phantom Wallet address.",
  },
  {
    id: 3,
    header: "Go to Uniswap",
    icon: <FaWallet className="icon" />,
    text: "Utilize Phantom's in-app wallet or a decentralized exchange like Jupiter to swap your SOL for $KENTO. Alternatively, for a multichain purchase, consider using the integrated Mayan swap or swapping through Jupiter if you already possess $SOL.",
  },
];

function BuySection() {
  return (
    <BuySectionWrapper id="buy">
      <MainContainer>
        <AnimationOnScroll animateIn="animate__bounceInDown">
          <BuyHeader>How To Buy</BuyHeader>
        </AnimationOnScroll>
        <MainRow>
          <CardWrapper>
            {cardData.map((item, index) => (
              <AnimationOnScroll animateIn="animate__bounceInUp">
                <Card className={index > 0 && "mt-5"}>
                  <CardImgDiv>{item.icon}</CardImgDiv>
                  <CardTextDiv>
                    <h2 className="header">{item.header}</h2>
                    <p className="subText">{item.text}</p>
                  </CardTextDiv>
                </Card>
              </AnimationOnScroll>
            ))}
          </CardWrapper>
        </MainRow>
        {/* <MainRow className="bottomRow">
          <MainCol lg={6}>
            <AnimationOnScroll animateIn="animate__lightSpeedInLeft">
           
              <iframe
                src="https://app.uniswap.org/#/swap?outputCurrency=0xb7A82c057966f306D4A38752B0002BDCC4CC66f4&chain=mainnet"
                title="description"
                className="uniswapiframe"
                width={"100%"}
                height={550}
              ></iframe>
            </AnimationOnScroll>
          </MainCol>
          <MainCol lg={6}>
            <AnimationOnScroll animateIn="animate__lightSpeedInRight">
              <img className="img-fluid bottomImg" src={roadmap2} />
            </AnimationOnScroll>
          </MainCol>
        </MainRow> */}
      </MainContainer>
      <img className="img-fluid" src={divider} />
    </BuySectionWrapper>
  );
}

export default BuySection;
