export const Images = {
  web3: {
    metamask: require("./web3/metamask.png")?.default,
    wallet: require("./web3/wallet.png")?.default,
  },
  login: {
    bg: require("./images/bg-img.png")?.default,
  },
};

export { default as logo } from "./images/logo.png";
export { default as heroMain } from "./images/heroMain.png";
export { default as dextools } from "./images/dextools.png";
export { default as ether } from "./images/ether.png";
export { default as divider } from "./images/divider.png";
export { default as about } from "./images/about.png";
export { default as tokenomics } from "./images/tokenomics.png";
export { default as heroImg } from "./images/heroImg.png";
export { default as heroCont } from "./images/heroCont.png";

export { default as roadmap1 } from "./images/roadmap1.png";
export { default as roadmap2 } from "./images/roadmap2.png";

export { default as gif } from "./images/table.gif";
export { default as gif1 } from "./images/herogif.gif";

export { default as quote } from "./images/quote.png";
export { default as heroimg2 } from "./images/heroImg2.png";
export { default as tweetOne } from "./images/tweetOne.png";
export { default as tweetTwo } from "./images/tweetTwo.png";
export { default as heroMainImg } from "./images/heroMainImg.png";
export { default as tweetThree } from "./images/tweetThree.png";
export { default as solana } from "./images/solana.svg";
export { default as twitter } from "./images/twitter.svg";
