import React from "react";
import {
  Card,
  TokenomicsHeader,
  TokenomicsWrapper,
  TokenSupplyText,
  CEXListingText,
} from "./element";
import { MainCol, MainContainer, MainRow } from "components/common";
import { divider, tokenomics } from "assets";
import { AnimationOnScroll } from "react-animation-on-scroll";

function TokenomicsSection() {
  return (
    <TokenomicsWrapper id="tokenomics">
      <MainContainer>
        <AnimationOnScroll animateIn="animate__zoomIn">
          <TokenomicsHeader>Tokenomics</TokenomicsHeader>
        </AnimationOnScroll>

        <MainRow className="marginRow">
          <MainCol lg={6}>
            <AnimationOnScroll animateIn="animate__slideInUp">
              <TokenSupplyText>
                Token Supply: <br /> 666,069,000,000
              </TokenSupplyText>
              <Card>
                No Taxes, No Bullshit. It’s that simple. <br /> <br />
                93.34% of the tokens were sent to the liquidity pool, LP tokens
                were burnt, and contract is renounced. The remaining 6.66% of
                the supply is being held in a wallet only to be used as tokens
                for future centralized exchange listings, bridges, and liquidity
                pools.
              </Card>
            </AnimationOnScroll>
          </MainCol>
          <MainCol lg={6}>
            <AnimationOnScroll animateIn="animate__slideInUp">
              {/* <CEXListingText>
                CEX Listings: <br /> 6.66%
              </CEXListingText> */}
              <img className="img-fluid heroImg" src={tokenomics} />
            </AnimationOnScroll>
          </MainCol>
        </MainRow>
      </MainContainer>
      <img className="img-fluid" src={divider} />
    </TokenomicsWrapper>
  );
}

export default TokenomicsSection;
