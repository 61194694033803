import { MainCol, MainContainer, MainRow } from "components/common";
import {
  AboutSectionWrapper,
  AboutSubText,
  AboutText,
  ImageDiv,
  ImgWrapper,
  TableDiv,
  Title,
} from "./styles";
import { divider, about, gif, tweetOne, tweetTwo, tweetThree } from "assets";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Table } from "antd";

function AboutSection() {
  const dataSource = [
    {
      key: "1",
      name: "BONK",
      age: "1 dickillion",
      address: "$1.5 Billions",
    },
    {
      key: "2",
      name: "WIF",
      age: "2 jizzillion",
      address: "$450 Millions",
    },
    {
      key: "3",
      name: "MYRO",
      age: "3 assillion",
      address: "$208 Millions",
    },
    {
      key: "4",
      name: "KENTO",
      age: "Loading...",
      address: "???",
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "How many X's",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Marketcap",
      dataIndex: "address",
      key: "address",
    },
  ];
  return (
    <AboutSectionWrapper id="story">
      <MainContainer>
        <MainRow>
          <MainCol lg={6}>
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <img className="img-fluid heroMain" src={about} />
            </AnimationOnScroll>
          </MainCol>
          <MainCol lg={6}>
            <AnimationOnScroll animateIn="animate__zoomIn">
              <AboutText>Story</AboutText>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInRight">
              <AboutSubText>
                {/* Pepe has emerged as a formidable player in the world of cryptocurrencies, displaying the potential to surpass $DOGE as the latter's relevance gradually diminishes. Our team is dedicated to following the trailblazing efforts of Pepe and lending our support to the "Make Memecoin Great Again" movement. Our primary objective is to establish $PEPE as the true king of memes, superseding Doge, and subsequently, to position our $KENTO coin as the successor to Shiba, rendering it obsolete.
                <br /> <br /> As the oldest meme, Pepe has rightfully earned its place at the top, and we firmly believe that our $KENTO coin deserves the second spot. With the unyielding support of the Memecoin and NFT community. We will MAKE MEMECOIN GREAT AGAIN #MMGA */}
                Yo, what’s up fam! If you ain’t heard yet, $KENTO is the hottest
                meme coin out there, and I’m about to tell you why. First of
                all, let’s talk about those other meme coins. They’re all just
                copycats trying to ride the wave of Dogecoin’s success. But
                Kento Puppet? That’s original, baby! And you know what they say,{" "}
                <br />
                &emsp; &emsp;&emsp;
                <i>
                  <u>“Originality is the key to success.”</u>
                </i>{" "}
                <br />
                But it’s not just about being original. $KENTO has got some
                serious community to back it up.
              </AboutSubText>
            </AnimationOnScroll>
          </MainCol>
        </MainRow>

        <TableDiv>
          <MainRow>
            <MainCol lg={6}>
              <Title>Memecoins Data Analysis</Title>
              <Table dataSource={dataSource} columns={columns} />
            </MainCol>
            <MainCol lg={6}>
              <ImgWrapper>
                <img src={gif} />
              </ImgWrapper>
            </MainCol>
          </MainRow>
        </TableDiv>

        <AnimationOnScroll animateIn="animate__fadeInUp">
          <AboutSubText className="bottomText">
            it’s not just me who’s hyped about $KENTO. Check out what some
            famous Americans have to say about it.
          </AboutSubText>
          <MainRow>
            <MainCol lg={4}>
              <ImageDiv>
                <img src={tweetOne} className="img-fluid tweetOne" />
              </ImageDiv>
            </MainCol>
            <MainCol lg={4}>
              <ImageDiv>
                <img src={tweetTwo} className="img-fluid tweetTwo" />
              </ImageDiv>
            </MainCol>
            <MainCol lg={4}>
              <ImageDiv>
                <img src={tweetThree} className="img-fluid tweetOne" />
              </ImageDiv>
            </MainCol>
          </MainRow>
        </AnimationOnScroll>
      </MainContainer>
      <img className="img-fluid" src={divider} />
    </AboutSectionWrapper>
  );
}

export default AboutSection;
