import styled, { createGlobalStyle } from "styled-components";
const GlobalStyle = createGlobalStyle`
  * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
 font-family: 'Inter', sans-serif !important; 
  } 
 body
{
  overflow-x: hidden !important;
 font-family: 'Inter', sans-serif !important; 
 scroll-behavior: smooth !important;   
}



.uniswapiframe {
  border-radius: 20px !important;
  margin-top: 1rem;
}

 /* Loader */
 .cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
 
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}


 
/* Models styles  */
.ant-modal-footer
  {
    display: none;
  }
  .ant-modal-close
  {
    display: none;
  }
  .ant-modal-content
  {
   background: none;
  }
  .ant-modal-body
  {
    padding: 0px;
  }
  .ant-modal-mask
  {
    background-color: rgb(0 0 0 / 78%) ;
  }

  .ant-modal > .ant-modal-content {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .closeModelInput
  {
    transform: translateY(-2px);
  }
    .ant-space-align-baseline
  {
    display: flex;
    justify-content:center  ;
  }
  .modalInputMale
  {
    border-top-right-radius:7px;
  border-bottom-right-radius:7px;
  transform: translateX(-6px);
  }
  .ant-form-vertical .ant-form-item-label>label {
  
 
    font-weight:600;
}

  /* Models styles */


.typeWriterEffect {
  font-family: "Bruno Ace SC", cursive !important;
  color: #fff;
  font-size: 48px;
}



`;

export default GlobalStyle;
