import styled from "styled-components";

export const NavbarWrapper = styled.div`
  overflow: hidden;

  .bg-light {
    background: linear-gradient(270deg, #030014 0.24%, #000d0f 99.71%);

    border-bottom: 2px solid #fff;

    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }

  .logo {
    height: 70px;
    width: 70px;
    cursor: pointer;
  }

  .navbar-light .navbar-nav .nav-link {
    font-family: "Bruno Ace SC", cursive !important;
    color: #fff;
    font-size: 18px;
    margin-left: 20px;
    position: relative;

    @media (max-width: 991.98px) {
      text-align: center;
      margin-left: 0;
    }

    &.nav-link.active {
      color: #fff !important;
    }

    &::after {
      content: "";
      position: absolute;
      width: 85%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 5px;
      background-color: #fff;
      transform-origin: bottom center;
      transition: transform 0.25s ease-out;

      @media (max-width: 991.98px) {
        width: 25%;
        left: 38%;
      }
    }

    &:hover::after {
      transform: scaleX(1);
      transform-origin: bottom center;
    }
  }
`;

export const NavBtn = styled.button`
  /* background: #7f542e; */
  border: 1px solid #fff;
  padding: 10px 20px;
  color: #fff;
  font-family: "Bruno Ace SC", cursive !important;
  transition: 0.3s ease-in-out all;
  border-radius: 50px;
  background-color: transparent;

  @media (max-width: 991.98px) {
    display: block;
    margin: 1rem auto;
  }

  &:hover {
    animation: rubberBand;
    animation-duration: 1s;
  }
`;
