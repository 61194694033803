import styled from "styled-components";

export const BuySectionWrapper = styled.div`
  overflow: hidden;
  background: linear-gradient(270deg, #030014 0.24%, #000d0f 99.71%);
  background-size: 400% 400%;
  animation: gradient 8s ease infinite;

  .container {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .bottomRow {
    margin-top: 4rem;
  }

  .mainImg {
    height: 400px;
    display: block;
    margin: 0rem auto 0 auto;

    @media (max-width: 991.98px) {
      display: block;
      margin: 1rem auto 0 auto;
    }

    @media (max-width: 575.98px) {
      height: 250px;
    }
    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }
  .bottomImg {
    height: 600px;
    display: block;
    margin: 0rem auto 0 auto;

    @media (max-width: 991.98px) {
      display: block;
      margin: 1rem auto 0 auto;
    }

    @media (max-width: 575.98px) {
      height: 250px;
    }
  }
`;

export const BuyHeader = styled.h2`
  font-family: "Bruno Ace SC", cursive !important;
  color: #fff;
  font-size: 48px;
  text-align: center;
`;

export const CardWrapper = styled.div`
  margin-top: 4rem;
`;

export const Card = styled.div`
  background: linear-gradient(
    45deg,
    rgba(153, 69, 255, 0.2) 10.43%,
    rgba(135, 82, 243, 0.2) 30.84%,
    rgba(84, 151, 213, 0.2) 49.4%,
    rgba(67, 180, 202, 0.2) 58.68%,
    rgba(40, 224, 185, 0.2) 69.81%,
    rgba(25, 251, 155, 0.2) 93.01%
  ) !important;
  padding: 20px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  min-height: 150px;

  &:hover {
    animation: bounce;
    animation-duration: 1s;
  }

  @media (max-width: 991.98px) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  @media (max-width: 575.98px) {
    padding: 20px 10px;
  }
`;

export const CardImgDiv = styled.div`
  .icon {
    font-size: 4rem;
    color: #fff;
  }
`;

export const CardTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 991.98px) {
    align-items: center;
  }

  .header {
    font-family: "Bruno Ace SC", cursive !important;
    color: #fff;
    font-size: 24px;
  }

  .subText {
    color: #fff;
    font-size: 24px;

    @media (max-width: 575.98px) {
      text-align: left;
    }
  }
`;
