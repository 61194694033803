import { MainCol, MainContainer, MainRow } from "components/common";
import {
  CaDiv,
  CaSpan,
  HeroSectionHeader,
  HeroSectionWrapper,
  HeroSubText,
  IconBtn,
  IconsDiv,
} from "./styles";
import {
  dextools,
  divider,
  ether,
  heroImg,
  heroCont,
  gif1,
  heroimg2,
  heroMainImg,
} from "assets";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaTelegramPlane } from "react-icons/fa";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Typewriter from "typewriter-effect";

function HeroSection() {
  return (
    <HeroSectionWrapper id="home">
      <MainContainer>
        <MainRow>
          <MainCol lg={6}>
            <AnimationOnScroll animateIn="animate__fadeInRight">
              <img className="img-fluid heroImg" src={heroMainImg} />
            </AnimationOnScroll>
          </MainCol>
          <MainCol lg={6}>
            <AnimationOnScroll animateIn="animate__fadeInRight">
              <img className="img-fluid heroImgCont" src={heroimg2} />
            </AnimationOnScroll>
          </MainCol>
        </MainRow>

        {/* <AnimationOnScroll animateIn="animate__zoomIn">
          <MainRow className="marginRow">
            <MainCol sm={6} lg={3}>
              <IconBtn>
                Ether
                <img className="img-fluid ether" src={ether} />
              </IconBtn>
            </MainCol>
            <MainCol sm={6} lg={3}>
              <IconBtn>
                Ether
                <img className="img-fluid ether" src={ether} />
              </IconBtn>
            </MainCol>
            <MainCol sm={6} lg={3}>
              <IconBtn>
                Ether
                <img className="img-fluid ether" src={ether} />
              </IconBtn>
            </MainCol>
            <MainCol sm={6} lg={3}>
              <IconBtn>
                Ether
                <img className="img-fluid ether" src={ether} />
              </IconBtn>
            </MainCol>
          </MainRow>
        </AnimationOnScroll> */}
        <CaDiv>
          <CaSpan
            onClick={() =>
              window.open(
                "",
                "_blank"
              )
            }
          >
            <a>SPL: 0x0000000000000000000000000000000000000</a>
          </CaSpan>
        </CaDiv>
      </MainContainer>
      <img className="img-fluid" src={divider} />
    </HeroSectionWrapper>
  );
}

export default HeroSection;
