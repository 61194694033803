const environment = {
    BACKEND_BASE_URL: "http://localhost:8080",
    CONTRACT_ADDRESS: '0xad39a52BDCC9003e60e8b1451D77bD5570A588D4',
    MORALIS_APP_ID: 'Cong0Rh8UwDPV5HC4U0nCPxiwEz5PAxeFLP2f3i0',
    MORALIS_SERVER_URL: 'https://nbnob0befkpo.usemoralis.com:2053/server'

};

/*if(process.env.REACT_APP_ENV==="development"){
       environment.BACKEND_BASE_URL= "http://localhost:8080"
   }
   
   
   if(process.env.REACT_APP_ENV==="production"){
       environment.BACKEND_BASE_URL= "https://owlnew.buildmydapp.co"
   }
   
   
   if(process.env.REACT_APP_ENV==="staging"){
       environment.BACKEND_BASE_URL= "https://api.illuminatiowls.com"
   }
   
   */
export default environment;
