import styled from "styled-components";

export const ContactWrapper = styled.div`
  overflow: hidden;
  background: linear-gradient(270deg, #030014 0.24%, #000d0f 99.71%);

  background-size: 400% 400%;
  animation: gradient 8s ease infinite;

  .container {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .marginRow {
    margin-top: 2rem;
  }

  .heroImg {
    display: block;
    margin: 1rem auto;
  }
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export const ContactHeader = styled.h2`
  font-family: "Bruno Ace SC", cursive !important;
  color: #fff;
  font-size: 48px;
  text-align: center;
`;

export const Text = styled.h5`
  font-family: "Bruno Ace SC", cursive !important;
  color: #fff;
  font-size: 28px;
  text-align: center;
`;

export const SocialWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 4rem;

  @media (max-width: 991.98px) {
    margin-top: 2rem;
  }

  .icons {
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
      transition: all 0.3s;
    }
  }
`;

export const LastText = styled.h5`
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin-top: 4rem;

  @media (max-width: 991.98px) {
    br {
      display: none;
    }

    .br {
      display: block !important;
    }
  }
`;

export const FooterText = styled.h5`
  font-family: "Bruno Ace SC", cursive !important;
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin-top: 1rem;
  padding-bottom: 1rem;
`;
