import { MainCol, MainContainer, MainRow } from "components/common";
import {
  Card,
  CardHeader,
  CardWrapper,
  LiContainer,
  RoadmapHeader,
  RoadmapWrapper,
  SubText,
} from "./element";
import { divider, roadmap1, roadmap2 } from "assets";
import { AnimationOnScroll } from "react-animation-on-scroll";

const cardData = [
  {
    id: 1,
    header: "Phase 1",
    firstLi: "Launch on Solana",
    thirdLi: null,
    fourthLi: null,
    fifthLi: null,
  },
  {
    id: 2,
    header: "Phase 2",
    firstLi: "NFT drop to Solana Mobile & $KENTO Holders",
    secondLi: null,
    thirdLi: null,
    fourthLi: null,
    fifthLi: null,
  },
  {
    id: 3,
    header: "Phase 3",
    firstLi: "$1 Billion marketcap",
    secondLi: null,
    thirdLi: null,
    fourthLi: null,
    fifthLi: null,
  },
];

function Roadmap() {
  return (
    <RoadmapWrapper id="roadmap">
      <MainContainer>
        <MainRow>
          <MainCol lg={4}>
            <AnimationOnScroll animateIn="animate__zoomIn">
              <img className="img-fluid heroMain" src={roadmap1} />
            </AnimationOnScroll>
          </MainCol>
          <MainCol lg={4}>
            <AnimationOnScroll animateIn="animate__zoomIn">
              <RoadmapHeader>Roadmap</RoadmapHeader>
              <Card className="topCard">
                <MainRow>
                  <MainCol lg={12}>
                    <p className="topCardText">Phase 1: Make a new Raydium</p>
                  </MainCol>
                  <MainCol lg={12}>
                    <p className="topCardText">
                      Phase 2: Make a new Magic Eden
                    </p>
                  </MainCol>
                  <MainCol lg={12}>
                    <p className="topCardText">Phase 3: Make a new Binance</p>
                  </MainCol>
                </MainRow>
              </Card>
            </AnimationOnScroll>
          </MainCol>
          <MainCol lg={4}>
            <AnimationOnScroll animateIn="animate__zoomIn">
              <img className="img-fluid heroMain" src={roadmap2} />
            </AnimationOnScroll>
          </MainCol>
        </MainRow>
        <AnimationOnScroll animateIn="animate__slideInUp">
          <SubText>Just Kidding, here's the real roadmap</SubText>
        </AnimationOnScroll>

        <CardWrapper>
          <MainRow>
            {cardData.map((item) => (
              <MainCol lg={4}>
                <AnimationOnScroll animateIn="animate__slideInUp">
                  <Card>
                    <CardHeader>{item.header}</CardHeader>

                    <LiContainer>
                      <li>{item.firstLi}</li>
                    </LiContainer>
                    {item?.secondLi && (
                      <LiContainer>
                        <li>{item.secondLi}</li>
                      </LiContainer>
                    )}
                    {item?.thirdLi && (
                      <LiContainer>
                        <li>{item.thirdLi}</li>
                      </LiContainer>
                    )}
                    {item?.fourthLi && (
                      <LiContainer>
                        <li>{item?.fourthLi}</li>
                      </LiContainer>
                    )}
                    {item?.fifthLi && (
                      <LiContainer>
                        <li>{item?.fifthLi}</li>
                      </LiContainer>
                    )}
                  </Card>
                </AnimationOnScroll>
              </MainCol>
            ))}
          </MainRow>
        </CardWrapper>
      </MainContainer>
      <img className="img-fluid" src={divider} />
    </RoadmapWrapper>
  );
}

export default Roadmap;
