import { Navbar } from "components/common";
import HeroSection from "./heroSection";
import AboutSection from "./aboutSection";
import BuySection from "./howToBuy";
import TokenomicsSection from "./tokenomicsSection";
import ContactSection from "./contactSection";
import Roadmap from "./roadmap";

const HomeCom = () => {
  return (
    <div>
      <Navbar />
      <HeroSection />
      <AboutSection />
      <BuySection />
      <TokenomicsSection />

      <Roadmap />
      <ContactSection />
    </div>
  );
};

export default HomeCom;
