import { MainContainer } from "components/common";
import React from "react";
import {
  ContactHeader,
  ContactWrapper,
  FooterText,
  LastText,
  SocialWrapper,
  Text,
} from "./elements";
import logo from "../../../assets/images/logo.png";
import { BsTelegram, BsDiscord } from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import { SiEthereum } from "react-icons/all";
import { divider, solana, twitter } from "assets";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

const ContactSection = () => {
  return (
    <ContactWrapper id="contact">
      <MainContainer>
        <AnimationOnScroll animateIn="animate__zoomIn">
          <ContactHeader>CONTACT</ContactHeader>
          <div className="d-flex gap-3 align-items-center justify-content-center mt-5">
            <img src={logo} width={60} />
            <Text>$kento Puppet</Text>
          </div>

          <SocialWrapper>
            <BsTelegram
              fontSize={80}
              color="#ffffff"
              cursor="pointer"
              className="icons"
            />

            <Image 
            onClick={() => window.open("https://x.com/kentoSolana","_blank")}
            src={twitter} width={"80px"} className="icons" />
            <Image src={solana} width={"80px"} className="icons" />
          </SocialWrapper>
        </AnimationOnScroll>

        <AnimationOnScroll animateIn="animate__slideInUp">
          <LastText>
            $KENTO coin has no association with Puppeteer or monkey business.
            <br /> This token is simply paying homage to a meme we all love and
            recognize. <br /> <br className="br" /> <br className="br" />
            $KENTO is a meme coin with no intrinsic value or expectation of
            financial return.
            <br /> $KENTO is for entertainment purposes only.
            <br />
          </LastText>
        </AnimationOnScroll>
      </MainContainer>
      <img className="img-fluid" src={divider} />

      <FooterText>
        © {new Date().getFullYear()} by KENTO The Puppet. All rights reserved!
      </FooterText>
      <FooterText>KENTOvip@gmail.com</FooterText>
    </ContactWrapper>
  );
};

export default ContactSection;
