import { NavBtn, NavbarWrapper } from "./element";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { MainContainer } from "components/common";
import { divider, logo } from "assets";

function Topbar() {
  return (
    <NavbarWrapper>
      <Navbar bg="light" expand="lg">
        <MainContainer>
          <Navbar.Brand href="#home">
            <img className="img-fluid logo" src={logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#story">Story</Nav.Link>
              <Nav.Link href="#buy">how to buy</Nav.Link>
              <Nav.Link href="#tokenomics">tokenomics</Nav.Link>
              <Nav.Link href="#roadmap">roadmap</Nav.Link>
            </Nav>
            <NavBtn onClick={() => window.open("https://app.uniswap.org/#/swap?outputCurrency=0xb7A82c057966f306D4A38752B0002BDCC4CC66f4&chain=mainnet", "_blank")}>Buy Now</NavBtn>
          </Navbar.Collapse>
        </MainContainer>
      </Navbar>
    </NavbarWrapper>
  );
}

export default Topbar;
