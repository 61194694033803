import styled from "styled-components";

export const AboutSectionWrapper = styled.div`
  overflow: hidden;
  background: linear-gradient(270deg, #030014 0.24%, #000d0f 99.71%);

  background-size: 400% 400%;
  animation: gradient 8s ease infinite;
  .container {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .heroMain {
    height: 400px;
    display: block;
    margin: 4rem auto 0 auto;

    @media (max-width: 991.98px) {
      display: block;
      margin: 0rem auto;
    }

    @media (max-width: 575.98px) {
      height: 250px;
    }
  }
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export const AboutText = styled.h2`
  font-family: "Bruno Ace SC", cursive !important;
  color: #fff;
  font-size: 48px;
  text-align: center;

  @media (max-width: 991.98px) {
    margin-top: 2rem;
  }
`;

export const AboutSubText = styled.p`
  margin: 0;
  font-size: 24px;
  color: #fff;

  &.bottomText {
    margin: 0;
    margin-top: 4rem;
    text-align: center;

    @media (max-width: 575.98px) {
      text-align: start;
    }
  }
`;

export const Title = styled.h5`
  font-size: 24px;
  color: #fff;
  padding-bottom: 1rem;
  font-family: "Bruno Ace SC", cursive !important;
  /* margin-top: 3rem; */
`;

export const TableDiv = styled.div`
  margin-top: 6rem;

  .ant-table-wrapper .ant-table-pagination.ant-pagination {
    display: none;
  }

  .ant-table {
    background: linear-gradient(
      45deg,
      rgba(153, 69, 255, 0.2) 10.43%,
      rgba(135, 82, 243, 0.2) 30.84%,
      rgba(84, 151, 213, 0.2) 49.4%,
      rgba(67, 180, 202, 0.2) 58.68%,
      rgba(40, 224, 185, 0.2) 69.81%,
      rgba(25, 251, 155, 0.2) 93.01%
    ) !important;
  }

  .ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: transparent;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    color: #fff;
  }

  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    color: #fff;
    background: transparent;
  }
`;

export const ImgWrapper = styled.div`
  text-align: center;
  justify-content: center;
`;

export const ImageDiv = styled.div`
  margin-top: 2rem;

  img {
    @media (max-width: 991.98px) {
      width: 100%;
    }
  }

  .tweetOne {
    transform: translateY(5px);

    @media (max-width: 991.98px) {
      transform: translateY(0);
    }
  }
`;
