import styled from "styled-components";

export const TokenomicsWrapper = styled.div`
  overflow: hidden;
  background: linear-gradient(270deg, #030014 0.24%, #000d0f 99.71%);

  background-size: 400% 400%;
  animation: gradient 8s ease infinite;
  .container {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .marginRow {
    margin-top: 2rem;
  }

  .heroImg {
    height: 400px;
    display: block;
    margin: 2rem auto 0 auto;

    @media (max-width: 991.98px) {
      display: block;
      margin: 2rem auto 0 auto;
    }

    @media (max-width: 575.98px) {
      height: 250px;
    }
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export const TokenomicsHeader = styled.h2`
  font-family: "Bruno Ace SC", cursive !important;
  color: #fff;
  font-size: 48px;
  text-align: center;
`;

export const TokenSupplyText = styled.h2`
  font-family: "Bruno Ace SC", cursive !important;
  color: #fff;
  font-size: 32px;
  text-align: center;

  @media (max-width: 575.98px) {
    font-size: 24px;
  }
`;

export const Card = styled.div`
  padding: 20px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  background: linear-gradient(
    45deg,
    rgba(153, 69, 255, 0.2) 10.43%,
    rgba(135, 82, 243, 0.2) 30.84%,
    rgba(84, 151, 213, 0.2) 49.4%,
    rgba(67, 180, 202, 0.2) 58.68%,
    rgba(40, 224, 185, 0.2) 69.81%,
    rgba(25, 251, 155, 0.2) 93.01%
  ) !important;
  font-size: 20px;
  margin-top: 2rem;
  color: #fff;

  &:hover {
    animation: headShake;
    animation-duration: 1s;
  }

  @media (max-width: 575.98px) {
    padding: 20px 10px;
    text-align: left;
  }
`;

export const CEXListingText = styled.p`
  color: #fff;
  font-size: 24px;
  margin: 0;
  text-align: center;

  @media (max-width: 991.98px) {
    margin-top: 2rem;
  }
`;
