import styled from "styled-components";

export const HeroSectionWrapper = styled.div`
  overflow: hidden;
  background: linear-gradient(270deg, #030014 0.24%, #000d0f 99.71%);

  background-size: 400% 400%;
  animation: gradient 8s ease infinite;

  .container {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .heroImgCont {
    /* height: 350px;
    width: 710px; */

    display: block;
    margin: 0rem auto 0 auto;

    @media (max-width: 991.98px) {
      display: block;
      margin: 2rem auto 0 auto;
    }

    @media (max-width: 575.98px) {
      height: 250px;
    }
    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }

  .heroImg {
    height: 400px;
    display: block;
    margin: 0rem auto 0 auto;

    @media (max-width: 991.98px) {
      display: block;
      margin: 2rem auto 0 auto;
    }

    @media (max-width: 575.98px) {
      height: 250px;
    }
  }

  .marginRow {
    margin-top: 4rem;
  }
`;

export const HeroSectionHeader = styled.h2`
  font-family: "Bruno Ace SC", cursive !important;
  color: #fff;
  font-size: 48px;
  margin-top: 4rem;

  @media (max-width: 991.98px) {
    margin-top: 0rem;
    text-align: center;
  }
`;

export const HeroSubText = styled.p`
  margin: 0;
  font-size: 24px;
  color: #fff;

  @media (max-width: 991.98px) {
    text-align: center;
  }
`;

export const IconsDiv = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  @media (max-width: 991.98px) {
    justify-content: center;
    margin-top: 2rem;
  }

  .twitterDiv {
    background-color: #1b9df0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    width: 50px;
    height: 50px;
    cursor: pointer;

    &:hover {
      animation: rubberBand;
      animation-duration: 1s;
    }
  }

  .twitterIcon {
    color: #fff;
    font-size: 32px;
  }

  .dextools {
    height: 60px;
    width: 60px;
    cursor: pointer;

    &:hover {
      animation: rubberBand;
      animation-duration: 1s;
    }
  }
  .ether {
    height: 50px;
    width: 50px;
    cursor: pointer;

    &:hover {
      animation: rubberBand;
      animation-duration: 1s;
    }
  }
`;

export const IconBtn = styled.button`
  width: 100%;
  height: 50px;
  color: #fff;
  font-size: 18px;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  &:hover {
    animation: jello;
    animation-duration: 1s;
  }

  @media (max-width: 991.98px) {
    margin-bottom: 2rem;
  }

  .ether {
    height: 20px;
    width: 20px;
  }
`;
export const CaDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CaSpan = styled.div`
  color: white;
  font-size: 18px;
  cursor: pointer;
  @media (max-width: 600px) {
    margin-top: 30px;
    margin-bottom: -20px;
    font-size: 11.5px !important;
  }
`;
