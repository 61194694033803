import styled from "styled-components";

export const RoadmapWrapper = styled.div`
  overflow: hidden;
  background: linear-gradient(270deg, #030014 0.24%, #000d0f 99.71%);
  background-size: 400% 400%;
  animation: gradient 8s ease infinite;
  .container {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .heroMain {
    height: 400px;
    display: block;
    margin: 0rem auto 0 auto;

    @media (max-width: 991.98px) {
      display: block;
      margin: 0rem auto 0 auto;
    }

    @media (max-width: 575.98px) {
      height: 250px;
    }
  }
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export const RoadmapHeader = styled.h2`
  font-family: "Bruno Ace SC", cursive !important;
  color: #fff;
  font-size: 48px;
  text-align: center;
`;

export const Card = styled.div`
  background: linear-gradient(
    45deg,
    rgba(153, 69, 255, 0.2) 10.43%,
    rgba(135, 82, 243, 0.2) 30.84%,
    rgba(84, 151, 213, 0.2) 49.4%,
    rgba(67, 180, 202, 0.2) 58.68%,
    rgba(40, 224, 185, 0.2) 69.81%,
    rgba(25, 251, 155, 0.2) 93.01%
  ) !important;
  padding: 20px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;

  &:hover {
    animation: pulse;
    animation-duration: 1s;
  }

  @media (max-width: 991.98px) {
    margin-bottom: 2rem;
  }

  &.topCard {
    margin-top: 2rem;
  }

  .topCardText {
    text-align: center;
    margin: 0;
    font-size: 20px;
    color: #fff;
  }
`;

export const SubText = styled.p`
  margin: 0;
  font-size: 24px;
  color: #fff;
  width: 75%;
  text-align: center;
  display: block;
  margin: 0rem auto;

  @media (max-width: 991.98px) {
    width: 100%;
    margin: 1rem auto;
  }
`;

export const CardWrapper = styled.div`
  margin-top: 4rem;

  @media (max-width: 991.98px) {
    margin-top: 2rem;
  }
`;

export const CardHeader = styled.h2`
  font-family: "Bruno Ace SC", cursive !important;
  color: #fff;
  font-size: 20px;
  text-align: center;
`;

export const LiContainer = styled.div`
  li {
    margin: 0;
    color: #fff;
    font-size: 16px;
  }
`;
